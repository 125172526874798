<template>
<v-layout column>
    <v-flex>
        <h2>
            <v-icon class="mr-2">mdi-chart-donut-variant</v-icon>Stock Report
        </h2>
    </v-flex>
    <v-container>
        <v-flex>
            <v-layout align-center>
                <v-flex sm3 class="mr-3">
                    <input-date v-model="startDate" label="Stock Date"></input-date>
                </v-flex>
                <!-- <v-flex xs4>
                    <input-date v-model="endDate"></input-date>
                    {{endDate}}
                </v-flex> -->
                <v-flex>
                    <v-btn mb-6 outlined :style="themeInverted" @click="generateReport()">Generate</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex>
            <v-container>
                <v-layout v-if="items.length>0" column>
                    <v-flex text-center>
                        <h2 class="report-heading">Stock Report - {{startDate}}</h2>
                    </v-flex>
                    <v-flex text-right>
                        <export-to-csv :data="items" :fileName="`stock-report-${startDate}`"></export-to-csv>
                        <export-to-xls :data="items" :fileName="`stock-report-${startDate}`"></export-to-xls>
                    </v-flex>
                    <v-simple-table>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Product</th>
                            <th>Opening Stock</th>
                            <th>Purchased Stock</th>
                            <th>Sold Stock</th>
                            <th>Current Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.code">
                            <td>{{item._id}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.openingStock ? item.openingStock : 0}}</td>
                            <td>{{item.purchasedStock}}</td>
                            <td>{{item.soldStock}}</td>
                            <td>{{item.currentStock}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                </v-layout>
                <v-flex text-center>
                    <progress-bar :show="loading"></progress-bar>
                </v-flex>
            </v-container>
        </v-flex>
    </v-container>
</v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
export default {
    components: {
        ExportToCsv,
        ExportToXls
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            items: [],
            loading: false,
            downloadType: 'csv'
        }
    },
    methods: {
        async generateReport() {
            try {
                this.loading = true
                this.items = await this.getItem(appConstants.STOCK_REPORT + "?startDate=" + this.$options.filters.defaultJsonDateFormat(this.startDate))
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
.report-heading{
    font-size: 1.2rem !important;
    font-weight: 500
}
</style>
